import Phaser from "phaser";

export default class LoadingScene extends Phaser.Scene {
    constructor() {
        super({
            key: "LoadingScene"
        });
    }

    preload() {
        this.cameras.main.setBackgroundColor("#0F7026");

        //Loading bar
        const progressBoxWidth = 320;
        const progressBoxHeight = 50;
        const progressBarHeight = 30;

        // Center coordinates
        const centerX = this.cameras.main.width / 2;
        const centerY = this.cameras.main.height / 2;

        // Create progress box (background for the loading bar)
        const progressBox = this.add.graphics();
        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(
            centerX - progressBoxWidth / 2, // Center the box horizontally
            centerY - progressBoxHeight / 2, // Center the box vertically
            progressBoxWidth,
            progressBoxHeight
        );

        // Create progress bar
        const progressBar = this.add.graphics();

        // Update the progress bar as assets load
        this.load.on('progress', (value) => {
            // Clear previous progress bar and redraw based on loading progress
            progressBar.clear();
            progressBar.fillStyle(0xffffff, 1);
            progressBar.fillRect(
                centerX - progressBoxWidth / 2 + 10, // Center with padding inside progress box
                centerY - progressBarHeight / 2, // Center vertically within the progress box
                (progressBoxWidth - 20) * value, // Scale width with loading progress
                progressBarHeight
            );
        });

        // Optional: Add loading text above the progress bar
        this.add.text(centerX, centerY - 50, "Loading...", {
            fontSize: "20px",
            color: "#ffffff"
        }).setOrigin(0.5, 0.5);

        // Clean up graphics when loading completes
        this.load.on('complete', () => {
            progressBox.destroy();
            progressBar.destroy();
        });

        // Retrieve the flavor from the URL parameter
        const urlParams = new URLSearchParams(window.location.search);
        const flavor = urlParams.get('flavor') || 'chocolate'; // Default to chocolate if no parameter

        // Load sound effects and music
        this.load.audio('jumpSFX', 'audio/jump.mp3');
        this.load.audio('slideSFX', 'audio/down.mp3');
        this.load.audio('rollSFX', 'audio/roll.mp3');
        this.load.audio('backgroundMusic', 'audio/background_music.mp3');
        this.load.audio('munch', 'audio/munch.mp3'); // Make sure the path is correct

        // Environments
        this.load.image('rock', 'environments/rock.png');
        this.load.image('building', 'environments/building.png');
        this.load.image('land', 'environments/land.png');

        // UI
        this.load.image('score', 'UI/score.png');
        this.load.image('time', 'UI/time.png');
        this.load.image('logo', 'UI/logo.png');

        // Collectible item based on flavor
        if (flavor === 'vanilla') {
            this.load.image('milo', 'collect/milovanilla.png'); // Load vanilla if flavor is vanilla
            this.load.image('splash', 'collect/splashvanilla.png'); // Load vanilla if flavor is vanilla
        } else {
            this.load.image('milo', 'collect/milochocolate.png'); // Default to chocolate
            this.load.image('splash', 'collect/splashchocolate.png'); // Default to chocolate
        }

        // Character
        this.load.spritesheet('character', 
            'characters/character.png',
            { frameWidth: 512, frameHeight: 512 }
        );

        // Instructions
        this.load.image('instruction', 'instruction/instruction.png');
        this.load.image('endScore', 'instruction/endScore.png');
        
    }

    create() {
        this.scene.start('GameScene');
    }

    update() {

    }
}
