import React, { useEffect } from 'react';
import Phaser from "phaser";
import { useNavigate } from "react-router-dom";
import GameScene from '../scenes/gameScene';
import LoadingScene from '../scenes/loadingScene';

const GameComponent = ({ config }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const gameConfig = {
      ...config,
      type: Phaser.WEBGL,
      scene: [LoadingScene, GameScene], // Add your scenes here
      parent: "phaser-container",
      physics: {
        default: "matter",
        matter: {
          gravity: { y: 1 },
          debug: false // Enable debug mode to show collision boxes
        }
      },
      render: {
        pixelArt: false, // Set to false to smooth images
        antialias: true, // Enable antialiasing for Canvas renderer
        antialiasGL: true, // Enable antialiasing for WebGL renderer
        
        
      },
    };

    const game = new Phaser.Game(gameConfig);

    // Listen for the custom event emitted from Phaser when the game ends
    game.events.on("navigateToCTA", () => navigate("/cta"));

    return () => {
      game.events.off("navigateToCTA"); // Clean up the event listener
      game.destroy(true);
    };
  }, [navigate, config]);

  return (
    <div id="phaser-container">
            <div className="mobile-portrait">
                <div id="game-content" /> {/* This will hold the Phaser canvas */}
            </div>
        </div>
  );
};

export default GameComponent;
