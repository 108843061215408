import React, { useEffect, useState } from "react";
import Phaser from "phaser";
import GameComponent from "../components/GameComponent";
import GameScene from "../scenes/gameScene";
import LoadingScene from "../scenes/loadingScene";

const isPortraitMode = () => window.innerWidth < window.innerHeight;
const isMobile = () => /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

const Home = () => {
  const [config, setConfig] = useState(null);
  const [isPortrait, setIsPortrait] = useState(isPortraitMode());

  useEffect(() => {
    const handleResize = () => setIsPortrait(isPortraitMode());
    window.addEventListener("resize", handleResize);

    const gameConfig = {
      type: Phaser.WEBGL,
      width: isPortrait ? window.innerWidth : Math.min(window.innerHeight * 9 / 16, 1080),
      height: isPortrait ? window.innerHeight : window.innerHeight,
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        width: isPortrait ? window.innerWidth : Math.min(window.innerHeight * 9 / 16, 1080),
        height: isPortrait ? window.innerHeight : window.innerHeight,
      },
      physics: {
        default: "matter",
        matter: {
          gravity: { y: 1 },
          debug: false,
        },
      },
      scene: [LoadingScene, GameScene],
    };

    setConfig(gameConfig);

    return () => window.removeEventListener("resize", handleResize);
  }, [isPortrait]);

  if (!config) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100vw",
        overflow: "hidden",
        backgroundColor: "#ffffff", // Optional: to add a background color to fill the entire screen
      }}
    >
      {isPortrait || !isMobile() ? (
        <div
          style={{
            position: "relative",
            width: isPortrait ? "100%" : "auto",
            height: isPortrait ? "100%" : "100vh",
            maxWidth: isPortrait ? "100%" : `${window.innerHeight * 9 / 16}px`,
            aspectRatio: isPortrait ? "auto" : "9 / 16",
            borderRadius: isPortrait ? "0" : "10px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GameComponent config={config} />
        </div>
      ) : (
        <div
          id="rotateAlert"
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#0F7127",
            padding: "40px", 
            boxSizing: "border-box"
          }}
        >
          <svg className="w-20 h-20 sm:w-32 sm:h-32" style={{ fill: "white" }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.323 8.616l-4.94-4.94a1.251 1.251 0 0 0-1.767 0l-10.94 10.94a1.251 1.251 0 0 0 0 1.768l4.94 4.94a1.25 1.25 0 0 0 1.768 0l10.94-10.94a1.251 1.251 0 0 0 0-1.768zM14 5.707L19.293 11 11.5 18.793 6.207 13.5zm-4.323 14.91a.25.25 0 0 1-.354 0l-1.47-1.47.5-.5-2-2-.5.5-1.47-1.47a.25.25 0 0 1 0-.354L5.5 14.207l5.293 5.293zm10.94-10.94l-.617.616L14.707 5l.616-.616a.25.25 0 0 1 .354 0l4.94 4.94a.25.25 0 0 1 0 .353zm1.394 6.265V18a3.003 3.003 0 0 1-3 3h-3.292l1.635 1.634-.707.707-2.848-2.847 2.848-2.848.707.707L15.707 20h3.304a2.002 2.002 0 0 0 2-2v-2.058zM4 9H3V7a3.003 3.003 0 0 1 3-3h3.293L7.646 2.354l.707-.707 2.848 2.847L8.354 7.34l-.707-.707L9.28 5H6a2.002 2.002 0 0 0-2 2z" />
            <path fill="none" d="M0 0h24v24H0z" />
          </svg>
          <span style={{ color: "white" }} className="text-center block text-sm sm:text-2xl">
            Please rotate your device to portrait mode
          </span>
        </div>
      )}
    </div>
  );
};

export default Home;
