// src/App.js
import React, { } from "react";
import ReactGA from "react-ga4";
// import Stats from "stats.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import GameComponent from "./components/GameComponent";
import Cta from "./pages/cta";
import StoreLocator from "./pages/StoreLocator";

function App() {
  ReactGA.initialize("G-D6XBT4C163");

  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "GAME_PLAYED" });

  // useEffect(() => {
  //   const stats = new Stats();
  //   stats.showPanel(0);

  //   const monitorPerformance = () => {
  //     stats.begin();
  //     requestAnimationFrame(() => {
  //       stats.end();
  //       monitorPerformance();
  //     });
  //   };

  //   monitorPerformance();

  //   return () => {
  //     document.body.removeChild(stats.dom);
  //   };
  // }, []);

  const router = createBrowserRouter(
    [
      { path: "/", element: <Home /> },
      { path: "/game", element: <GameComponent /> },
      { path: "/cta", element: <Cta /> },
      { path: "/StoreLocator", element: <StoreLocator /> },
    ],
    {
      future: {
        v7_startTransition: true,
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionErrorRevalidation: true,
        v7_relativeSplatPath: true, // Add this flag to address the warning
      },
    }
  );

  return <RouterProvider router={router} future={{ v7_startTransition: true }} />}

export default App;
