import React, { } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";

// Keyframes for the pop-up animation
const popUpAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url('/background/BG.png');
  background-size: cover; /* Ensure BG covers entire container */
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;

  @media (min-width: 520px) {
    width: calc(100vh * 9 / 16);
    height: 100vh;
    margin: auto;
  }
`;

// Full overlay for the copy image with animation
const CopyOverlay = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Make the overlay cover the entire container */
  height: 100%; /* Full height */
  object-fit: contain; /* Ensures the image covers the area without distorting */
  z-index: 1; /* Place it above the background but below the buttons */
  animation: ${popUpAnimation} 0.8s ease-out; /* Apply the pop-up animation */
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2vw;
  position: absolute;
  bottom: 10%; /* Adjusted for more consistent vertical alignment */
  left: 50%;
  transform: translateX(-50%);
  padding: 0 2vw; /* Adds padding to prevent overlap on smaller screens */
  z-index: 2; /* Ensure buttons are above the overlay image */

  @media (min-width: 768px) {
    bottom: 10%;
    gap: 1.5vw; /* Adjust gap for larger screens */
  }
`;

const Button = styled.img`
  width: 50vw; /* Adjust width to be more responsive */
  max-width: 150px;
  height: auto;
  cursor: pointer;
  animation: ${popUpAnimation} 1s ease-out; /* Apply the pop-up animation */
  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 1024px) {
    width: 12vw;
    max-width: 250px; /* Larger max width for bigger screens */
  }

  @media (min-width: 1440px) {
    width: 10vw;
    max-width: 280px;
  }
`;

const Cta = () => {
  const navigate = useNavigate();

  const handleBeliSekarang = () => {
    // GA tracking
    ReactGA.event({
      category: "END SCREEN",
      action: "ENGAGE_TAP_CTA_BUYNOW",
    });
    window.open('https://www.tokopedia.com/nestle-indonesia/etalase/milo-biskuit?utm_source=programmatic&utm_medium=brand&utm_campaign=milobiscuit-dt-novdec_201124_311224', '_blank');
  };

  const handleTokoTerdekat = () => {
    // GA tracking
    ReactGA.event({
      category: "END SCREEN",
      action: "ENGAGE_TAP_STORE_LOCATOR",
    });
    navigate('/StoreLocator');
  };

  return (
    <CtaContainer>
      {/* Full Overlay for copy.png */}
      <CopyOverlay src="/background/copy.png" alt="Overlay Text" />

      <ButtonContainer>
        <Button
          src="/button/TokoTerdekat.png"
          alt="Toko Terdekat"
          onClick={handleTokoTerdekat}
        />
        <Button
          src="/button/BeliSekarang.png"
          alt="Beli Sekarang"
          onClick={handleBeliSekarang}
        />
      </ButtonContainer>
    </CtaContainer>
  );
};

export default Cta;
